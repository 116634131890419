
import TypeIt from "typeit-react";

const Section = (props) => {
    const {children, mobileTop} = props
    
    return (<section 
        className={`w-screen max-w-screen-2xl mx-auto
         flex flex-col panel ${mobileTop ? "justify-center" : "justify-center"}`}
        >{children}
        </section>)

}


export const Interface = (props) => 
{
    return (
        
        <div className="flex flex-col items-center w-screen top-0 absolute textDiv" id="scrollerDiv">
       
            <AboutSection/>
            <SkillsSection/>
            <VueSection/>
            <ContactSection/>
        </div>
    );
}

const AboutSection = (props) => {
return (


    <Section mobileTop>
        <h1 className="text-7xl font-extrabold text-white leading-snug mt-8 md:mt-0" >
        <TypeIt
         options={{
            speed: 100,
            waitUntilVisible: true,
            loop: false,
          }}
      getBeforeInit={(instance) => {
    instance.pause(1650).type("Hi, I'm James").pause(350).delete(13).type("I'm a developer").pause(300).delete(18).pause(300).type("Scroll for my portfolio...")

    // Remember to return it!
    return instance;
  }}
/>

        </h1>
        <p className="text-3xl text-white mt-4">
            
   
        </p>
    </Section>
)
}
const skills = [
    {
        title:"React",
        level: 95,
    },
    {
        title:"Three.js",
        level: 80,
    }, 
    {
        title:"GSAP",
        level: 65,
    }, 


  ];

  const skills2 = [
    {
        title:"Vue.js",
        level: 95,
    },
    {
        title:"SCSS",
        level: 80,
    },
    {
        title:"GSAP",
        level: 50,
    }, 
  ];


const SkillsSection = () => {
    return (
        <Section>
         <div className="sectionContent">
            <h1 className="text-6xl font-bold text-white mb-5 underline">Planet.design</h1>
            <p className="text-white mb-10">A showcase of my three.js planet designer tool made with react. Design each element of your planet and share it with your friends</p>
            <h2 className="text-5xl font-bold text-white mb-5 ">Skills used</h2>
            <div className="space-y-4">
                {skills.map((skill,index) => (
                    <div className="w-64" key={index}>
                        <h3 className="text-xl font-bold text-white">{skill.title}</h3>
                        <div className="h-2 w-full bg-gray-200 rounded-full mt-2">
                            <div className="h-2 w-full bg-gray-200 rounded-full mt-2">
                                <div className="h-full bg-climate rounded-full" style={{width: `${skill.level}%` }}/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
          <a href="https://planet.design" target="_blank" > <button className="btn mt-10">View Project</button></a>
         </div>
        </Section>
    )

    
    }


    const VueSection = () => {
        return (
            <Section>
             <div className="sectionContent">
                <h1 className="text-6xl font-bold text-white mb-5 underline">VueQuiz.com</h1>
                <p className="text-white mb-10">VueQuiz.com is a free learning resource for the VUE framework. Test your knowledge via a series of multiple choice questions.</p>
                <h2 className="text-5xl font-bold text-white mb-5 ">Skills used</h2>
                <div className="space-y-4">
                    {skills2.map((skill,index) => (
                        <div className="w-64" key={index}>
                            <h3 className="text-xl font-bold text-white">{skill.title}</h3>
                            <div className="h-2 w-full bg-gray-200 rounded-full mt-2">
                                <div className="h-2 w-full bg-gray-200 rounded-full mt-2">
                                    <div className="h-full bg-climate-vue rounded-full" style={{width: `${skill.level}%` }}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
              <a href="https://vuequiz.com" target="_blank" > <button className="btn btn-vue mt-10 ">View Project</button></a>
             </div>
            </Section>
        )
    
        
        }



        const ContactSection = () => {
            return (
                <Section>
                 <div className="sectionContentEnd" data-aos="zoom-out">
                <div>
                    <h1 className="contactHeader">Contact</h1>
                </div>
                <div>
                    <p className="contactEmail">Email : jjdraper@pm.me</p>
                </div>
                </div>

                  {/* <a href="https://vuequiz.com" target="_blank" > <button className="btn btn-vue mt-10 ">View Project</button></a> */}

                </Section>
            )
        
            
            }
    
            
        