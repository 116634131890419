import React, { useEffect, useRef } from "react";
import { useAnimations, useFBX, useGLTF } from "@react-three/drei";

export function Avatar(props) {
  const {animation} = props;

  const group = useRef()

  const { nodes, materials } = useGLTF("models/64b538b893da602c1d907fb5.glb");

  const {animations: typingAnimation} = useFBX("animations/Typing.fbx")
  const {animations: standingAnimation} = useFBX("animations/Flying.fbx") 
  const {animations: fallingAnimation} = useFBX("animations/flat.fbx") 

  
  typingAnimation[0].name = "Typing";
  standingAnimation[0].name = "Standing";
  fallingAnimation[0].name = "Falling";


  const {actions} = useAnimations([typingAnimation[0],standingAnimation[0],fallingAnimation[0]], group)

  useEffect( () =>
  {
    let animationclipo = actions[animation]

    if (animation == "Typing")
    {
      animationclipo.clampWhenFinished = true;
      animationclipo.repetitions = Infinity
      animationclipo.reset().play()
      return ()  => {

        actions[animation].reset().fadeOut(0.5)
    }

    }
    if (animation == "Standing")
    {
      animationclipo.clampWhenFinished = true;
      animationclipo.repetitions = Infinity
      animationclipo.reset().play()
      return ()  => {

        actions[animation].reset().fadeOut(0.3)
    }
    }
    if (animation == "Falling")
    {
      animationclipo.clampWhenFinished = true;
      animationclipo.repetitions = 1
      animationclipo.timeScale = 0.8
      animationclipo.reset().play()
      return ()  => {

        actions[animation].reset().fadeOut(1)
    }
    }
  
    console.log(animation)
  

  },[animation])



  return (

    <group {...props} dispose={null} ref={group}>
      <group rotation-x={- Math.PI / 2 } rotation-z={-0.25}>
        <primitive object={nodes.Hips} />
        <skinnedMesh
          name="EyeLeft"
          geometry={nodes.EyeLeft.geometry}
          material={materials.Wolf3D_Eye}
          skeleton={nodes.EyeLeft.skeleton}
          morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
          morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
        />
        <skinnedMesh
          name="EyeRight"
          geometry={nodes.EyeRight.geometry}
          material={materials.Wolf3D_Eye}
          skeleton={nodes.EyeRight.skeleton}
          morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
          morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
        />
        <skinnedMesh
          name="Wolf3D_Head"
          geometry={nodes.Wolf3D_Head.geometry}
          material={materials.Wolf3D_Skin}
          skeleton={nodes.Wolf3D_Head.skeleton}
          morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
          morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
        />
        <skinnedMesh
          name="Wolf3D_Teeth"
          geometry={nodes.Wolf3D_Teeth.geometry}
          material={materials.Wolf3D_Teeth}
          skeleton={nodes.Wolf3D_Teeth.skeleton}
          morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
          morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Body.geometry}
          material={materials.Wolf3D_Body}
          skeleton={nodes.Wolf3D_Body.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
          material={materials.Wolf3D_Outfit_Bottom}
          skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
          material={materials.Wolf3D_Outfit_Footwear}
          skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Outfit_Top.geometry}
          material={materials.Wolf3D_Outfit_Top}
          skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Hair.geometry}
          material={materials.Wolf3D_Hair}
          skeleton={nodes.Wolf3D_Hair.skeleton}
        />
      </group>
    </group>
  );
}

useGLTF.preload("models/64b538b893da602c1d907fb5.glb");