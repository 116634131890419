import Experience from './Experience.jsx'
import { PerspectiveCamera, Scroll } from '@react-three/drei'
import { ScrollManager } from './ScrollManager'
import { Interface } from './Interface.jsx'
import { useLayoutEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { ScrollControls } from '@react-three/drei'
import { Menu } from './Menu.jsx'




export default function App()
{

    const [section, setSection] = useState(0);
    const [menuOpened, setMenuOpened] = useState(false);
    const [backgroundColor, setbackgroundColor] = useState("#00182d");

    return<>

        <Canvas
         shadows={{ type: "PCFSoftShadowMap" }}
        camera={ {
            fov: 40,
            near:1,
            far: 20,
            position: [ 0, 0, 10 ]
        } }
         >
        <color attach="background" args={[backgroundColor]} />

        <ScrollManager section={section} onSectionChange={setSection}/>
        <Experience section={section} menuOpened={menuOpened} setbackgroundColor={setbackgroundColor}/>
           
        </Canvas>
        <Interface/>
       {/* <Menu 
        onSectionChange={setSection}
        menuOpened={menuOpened} 
        setMenuOpened={setMenuOpened}/>       */}
    </>



}