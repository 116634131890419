import { useEffect } from 'react';
import { Avatar } from './Avatar'
import { motion } from 'framer-motion-3d'
import { animate, useMotionValue } from 'framer-motion';
import { useState } from 'react';
import { Environment } from '@react-three/drei';
import { Cloud } from '@react-three/drei';
import { Sparkles } from '@react-three/drei';
import { Stars } from '@react-three/drei';
import { LightHouse } from './LightHouse';
import { Float } from '@react-three/drei';
import { Computer } from './computer';

export default function Experience(props)

{
    const {section, menuOpened, setbackgroundColor} = props;
    const [currentAnimation, setCurrentAnimation] = useState("Typing");
    const cameraPositionX = useMotionValue();
    const cameraLookAtX = useMotionValue();

    const [currentJamesPosition, setCurrentJamesPosition] = useState()
    const [currentJamesRotation, setCurrentJamesRotation] = useState()
    const [currentScale, setCurrentScale] = useState()
    const [islandScale, setislandScale] = useState()
    const [computerScale, setcomputerScale] = useState()
    const [islandPosition, setislandPosition] = useState()
    const [computerPosition, setcomputerPosition] = useState()


    const mediaQuery = window.matchMedia('(max-width: 768px)')
    
    useEffect(() =>
    {
        if (section == 0){
            if (mediaQuery.matches) {
                // If mobile
                setCurrentAnimation("Typing")
                setbackgroundColor("#00182d")
                setCurrentJamesPosition([0,-3.1,5])
                setCurrentJamesRotation([0,0.2,0])
                setCurrentScale(2.2)
                setislandScale(0)
                setcomputerScale(0)
                setislandPosition([0,-2,0])
              }
            else{
                setCurrentAnimation("Typing")
                setbackgroundColor("#00182d")
                setCurrentJamesPosition([1.5,-3.1,5])
                setCurrentJamesRotation([0,-0.1,0])
                setCurrentScale(2.2)
                setislandScale(0)
                setcomputerScale(0)
                setislandPosition([0,-2,0])
            }
        }
        if (section == 1){
            if (mediaQuery.matches) {
                // If mobile
                setCurrentAnimation("Standing");
                setbackgroundColor("#2e3339")
                setCurrentJamesPosition([1.4,-1,5])
                setislandScale(0.0011)
                setcomputerScale(0)
                setislandPosition([0,2.5,0])
                setCurrentScale(0)

              }
            else{
            setCurrentAnimation("Standing");
            setbackgroundColor("#2e3339")
            setCurrentJamesPosition([1.4,-1,5])
            setCurrentScale(1.6)
            setislandScale(0.0030)
            setcomputerScale(0)
            }

        }
        if (section == 2){
            if (mediaQuery.matches) {
                // If mobile
                setCurrentAnimation("Standing");
                setbackgroundColor("#1a1a1a")
                setCurrentJamesPosition([1.4,-1,5])
                setislandScale(0)
                setCurrentScale(0)
                setcomputerScale(0.04)
                setcomputerPosition([0,1.8,0])

              }
            else{
                setCurrentAnimation("Standing");
                setbackgroundColor("#1a1a1a")
                setCurrentJamesPosition([1.4,-1,5])
                setislandScale(0)

                setcomputerScale(0.07)
                setcomputerPosition([0,-2.1,0])
            }
        }
        if (section == 3){
            if (mediaQuery.matches) {
                // If mobile
                setCurrentAnimation("Falling");
                setbackgroundColor("#1a1a1a")
                setCurrentJamesPosition([0,-1.3,4])
                setCurrentScale(1.5)
                setislandScale(0)
                setcomputerScale(0)
                setcomputerPosition([0,-2.1,0])

              }
            else{
                setCurrentAnimation("Falling");
                setbackgroundColor("#1a1a1a")
                setCurrentJamesPosition([1.4,-1,5])
                setislandScale(0)
                setcomputerScale(0)
                setcomputerPosition([0,-2.1,0])
            }
        }
    },[section])

    return <>
    

        {/* <OrbitControls makeDefault /> */}
        <motion.group position={currentJamesPosition} scale={currentScale} rotation={currentJamesRotation}>
            <Avatar animation={currentAnimation} />
        </motion.group>
        <Float
  speed={2.5} // Animation speed, defaults to 1
  rotationIntensity={0.2} // XYZ rotation intensity, defaults to 1
  floatIntensity={1.4} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
  floatingRange={[0, -0.2]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
>
        <LightHouse scale={islandScale} position={islandPosition} rotation-y={Math.PI/2.2} rotation-x={0.1}/>
        <Computer scale={computerScale} position={computerPosition} rotation-y={-1.6} rotation-x={0.8}/>
        </Float>

        <Environment
  background={false} // can be true, false or "only" (which only sets the background) (default: false)
  blur={0} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
  files={['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']}
  path="/"
  preset={"sunset"}
  scene={undefined} // adds the ability to pass a custom THREE.Scene, can also be a ref
  encoding={undefined} // adds the ability to pass a custom THREE.TextureEncoding (default: THREE.sRGBEncoding for an array of files and THREE.LinearEncoding for a single texture)
/>
{/* <Cloud
  opacity={0.2}
  speed={1} // Rotation speed
  width={1} // Width of the full cloud
  depth={2} // Z-dir depth
  segments={3}
  position-x={2.5} // Number of particles
/> */}
<Sparkles count={"200"} speed={"0.2"} scale={"20"} size={"6"} noise={"0"}  />
        {/* <directionalLight position={[0,2,-0.7]}  intensity={1}/> */}
        {/* <ambientLight intensity={0.05}/> */}
        <directionalLight position={[0,-3,10]} intensity={0.35} />
        {/* <directionalLight position={[0.2,-2, 0]} intensity={0.0} color={[256,256,256]}/> */}


    </>
}

