
import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Float } from "@react-three/drei";

export function LightHouse(props) {
  const group = useRef();

  const { nodes, materials, animations } = useGLTF(
    "models/lowpoly_small_floating_island_-_jean-yves_tran.glb"
  );
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group
          name="Sketchfab_model"
          position={[4.9096, 0, -11.6235]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <group
            name="5fcc8b9cde564f09874408aad71602d1fbx"
            rotation={[Math.PI / 2, 0, 0]}
          >
            <group name="Object_2">
              <group name="RootNode">
                <group
                  name="MainGlobe"
                  rotation={[-Math.PI / 2, 0, 0]}
                  scale={1000}
                >
                  <mesh
                    name="Icosphere_Material002_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Icosphere_Material002_0.geometry}
                    material={materials["Material.002"]}
                  />
                </group>
           
                <group
                  name="Tree3"
                  position={[-80.5676, 339.828, -35.5163]}
                  rotation={[-1.8436, 0.4341, 1.5276]}
                  scale={28.9535}
                >
                  <mesh
                    name="Cube_Material003_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube_Material003_0.geometry}
                    material={materials["Material.003"]}
                  />
                  <mesh
                    name="Cube_Material005_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube_Material005_0.geometry}
                    material={materials["Material.005"]}
                  />
                  <mesh
                    name="Cube_Material006_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube_Material006_0.geometry}
                    material={materials["Material.006"]}
                  />
                </group>
                <group
                  name="BigTree4"
                  position={[-734.1774, 471.8149, 77.006]}
                  rotation={[-Math.PI / 2, 0, 0]}
                  scale={10.6173}
                >
                  <mesh
                    name="Plane001_Material008_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane001_Material008_0.geometry}
                    material={materials["Material.008"]}
                  />
                  <mesh
                    name="Plane001_Material009_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane001_Material009_0.geometry}
                    material={materials["Material.009"]}
                  />
                </group>
                <group
                  name="Tree2"
                  position={[-556.5934, 291.7665, -389.842]}
                  rotation={[-1.7728, -0.0812, 3.0811]}
                  scale={[31.5507, 36.1393, 24.357]}
                >
                  <mesh
                    name="Cube001_Material003_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube001_Material003_0.geometry}
                    material={materials["Material.003"]}
                  />
                  <mesh
                    name="Cube001_Material005_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube001_Material005_0.geometry}
                    material={materials["Material.005"]}
                  />
                  <mesh
                    name="Cube001_Material006_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube001_Material006_0.geometry}
                    material={materials["Material.006"]}
                  />
                </group>
                <group
                  name="BigTree4"
                  position={[-678.5673, 470.9664, 118.3054]}
                  rotation={[-Math.PI / 2, 0, -1.8752]}
                  scale={[10.6173, 10.6173, 12.2528]}
                >
                  <mesh
                    name="Plane002_Material008_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane002_Material008_0.geometry}
                    material={materials["Material.008"]}
                  />
                  <mesh
                    name="Plane002_Material009_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane002_Material009_0.geometry}
                    material={materials["Material.009"]}
                  />
                </group>
                <group
                  name="BigTree3"
                  position={[-734.1774, 482.5463, 182.3248]}
                  rotation={[-1.7115, 0, 0]}
                  scale={10.6173}
                >
                  <mesh
                    name="Plane003_Material008_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane003_Material008_0.geometry}
                    material={materials["Material.008"]}
                  />
                  <mesh
                    name="Plane003_Material009_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane003_Material009_0.geometry}
                    material={materials["Material.009"]}
                  />
                </group>
                <group
                  name="BigTree2"
                  position={[-567.085, 430.5082, 77.006]}
                  rotation={[-1.7849, 0.2365, 0.1742]}
                  scale={[10.6173, 10.6173, 10.6174]}
                >
                  <mesh
                    name="Plane004_Material008_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane004_Material008_0.geometry}
                    material={materials["Material.008"]}
                  />
                  <mesh
                    name="Plane004_Material009_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane004_Material009_0.geometry}
                    material={materials["Material.009"]}
                  />
                </group>
                <group
                  name="bigTree1"
                  position={[-567.085, 422.0426, -22.2688]}
                  rotation={[-1.7804, 0.1208, 0.1489]}
                  scale={[10.5633, 9.1038, 9.0174]}
                >
                  <mesh
                    name="Plane005_Material008_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane005_Material008_0.geometry}
                    material={materials["Material.008"]}
                  />
                  <mesh
                    name="Plane005_Material009_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane005_Material009_0.geometry}
                    material={materials["Material.009"]}
                  />
                </group>
                <group
                  name="Plane006"
                  position={[859.5024, 468.4312, -85.6074]}
                  rotation={[-1.4742, 0.2839, 0.1287]}
                  scale={[10.6174, 10.6173, 10.6174]}
                >
                  <mesh
                    name="Plane006_Material008_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane006_Material008_0.geometry}
                    material={materials["Material.008"]}
                  />
                  <mesh
                    name="Plane006_Material009_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane006_Material009_0.geometry}
                    material={materials["Material.009"]}
                  />
                </group>
                <group
                  name="Tree1"
                  position={[697.286, 527.3203, 129.636]}
                  rotation={[-1.9226, 0.1597, -0.0617]}
                  scale={[10.6587, 10.9292, 12.6095]}
                >
                  <mesh
                    name="Plane007_Material008_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane007_Material008_0.geometry}
                    material={materials["Material.008"]}
                  />
                  <mesh
                    name="Plane007_Material009_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane007_Material009_0.geometry}
                    material={materials["Material.009"]}
                  />
                </group>
                <group
                  name="Plane008"
                  position={[-206.5062, 548.2029, 734.5486]}
                  rotation={[-1.4202, 0.0346, -0.0772]}
                  scale={[10.6124, 10.5661, 7.9742]}
                >
                  <mesh
                    name="Plane008_Material008_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane008_Material008_0.geometry}
                    material={materials["Material.008"]}
                  />
                  <mesh
                    name="Plane008_Material009_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane008_Material009_0.geometry}
                    material={materials["Material.009"]}
                  />
                </group>
                <group
                  name="Cube002"
                  position={[354.4803, 406.533, -35.5163]}
                  rotation={[-1.4086, -0.3642, -1.5918]}
                  scale={[30.6789, 32.1207, 42.3582]}
                >
                  <mesh
                    name="Cube002_Material003_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube002_Material003_0.geometry}
                    material={materials["Material.003"]}
                  />
                  <mesh
                    name="Cube002_Material005_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube002_Material005_0.geometry}
                    material={materials["Material.005"]}
                  />
                  <mesh
                    name="Cube002_Material006_0"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube002_Material006_0.geometry}
                    material={materials["Material.006"]}
                  />
                </group>
         
            
           
           
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("models/lowpoly_small_floating_island_-_jean-yves_tran.glb");

