import { useEffect, useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin);
export const ScrollManager = (props) =>
{
  
  const {section, onSectionChange} = props


const scrollTween = useRef();
let ctx = gsap.context(() => {
          
  function goToSection(i) {
              
              ctx.data.forEach((e) => {
                if (e.vars && e.vars.id === 'scrollTween') {
                  e.kill();
                }
              });

              ctx.add(() => {
                scrollTween.current = gsap.to("#scrollerDiv", {
                  scrollTo: { y: i * innerHeight, autoKill: false, ease: "Power3.easeInOut" },
                  duration: 0.45,
                  onComplete: () =>{
              
                  scrollTween.current = null

                },
                  onStart: () => {
                    document.getElementById("scrollerDiv").style.overflowY = "hidden"
                    setTimeout(function(){
                      document.getElementById("scrollerDiv").style.overflowY = "scroll"
                  }, 200);
                    onSectionChange(i);
                  },     
                  overwrite: false,
                });
  
              })

  } 

  useLayoutEffect(() => {  
  ctx.add(() => {   
            const sections = gsap.utils.toArray(".panel");
            sections.forEach((eachPanel, i) => {
     
                ScrollTrigger.create({
                    trigger: eachPanel,
                    scroller: "#scrollerDiv",
                    onEnter: () => !scrollTween.current && goToSection(i),
                  });
               
                  ScrollTrigger.create({
                    trigger: eachPanel,
                    start: "bottom bottom",
                    scroller: "#scrollerDiv",
                    onEnterBack: () => !scrollTween.current && goToSection(i)
                  });
           
               
                ScrollTrigger.defaults({
                  markers: false
                });
              });

        });
        return () => ctx.revert(); // <- cleanup!
      }, []);

})

}
